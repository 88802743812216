/// <reference path="variables.scss" />

/*************************************
Main layout styling
*************************************/
* {
    box-sizing: border-box;
}

html {
    font-size: $rootFontSize;
    height: 100%;
}

body {
    background-color: $bodyBackgroundColor;
    overflow-x: hidden !important;
    color:$bodyColor;
    line-height:$bodyLineHeight;

    .container {
        max-width: 144rem;
    }

    .spacer {
        height: 1000px;
    }

    #content-anchor {
        background: $white;
        min-height: 35rem;
    }
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}


/*Helpers*/
.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0 !important;
}

.no-shadow {
    box-shadow: none;
}

.word-break {
    word-break: break-word;
}

.no-bg {
    background: none !important;
}

//Hero Component Header Text
.hero-slider-component {
    .slide {
        .content-wrapper {
            .title {
                color: $white;
            }
        }
    }
}

//Login pop-up general style
.distributor-modal {
    .modal {
        background: rgba(54,54,54,0.7);
    }

    .modal-content {
        border: none;
        background: none;

        .modal-body {
            padding: 0;

            .card {
                text-align: center;
                padding: 15px 20px 30px;
            }

            .card-header {
                background: none;
                border-bottom: none;

                .close-button {
                    float: right;
                    margin: 0;
                    width: auto;
                    padding: 0;

                    .close-icon {
                        font-size: 3.5rem;
                        margin: 0;
                    }
                }
            }

            .account-login {
                margin-bottom: 3rem;

                .cart-login {
                    font-size: 3rem;
                    color: $grey60;
                    font-weight: 600;

                    &-request {
                        font-size: 2rem;
                        color: $accent;
                    }
                }
            }

            .create-btn {
                text-align: center;
                display: block;
                transition: all .2s ease-in-out;

                &:hover {
                    color: $accent;
                    transform: scale(1.1);
                    transition: all .2s ease-in-out;
                }
            }
        }
    }
}

.portal-login {
    p {
        font-size: 1.8rem;
        color: $portal-login-color;
        font-weight: 600;
    }

    a {
        color: inherit;
        font-size: inherit;
        text-decoration: underline;
        font-weight: inherit;
    }
}

//Add to list pop-up general
.add-list-modal {
    .new-list-dropdown {
        width: 100%;
        right: 0 !important;
        position: relative !important;

        form {
            .form-control {
                max-width: 100% !important;
                width: 100% !important;
            }
        }
    }
}

//Create new-list pop-up general style
.dropdown-wrapper {
    .modal {
        background-color: rgba(54,54,54,0.7);

        &.collapse.show {
            display: block;
            overflow: initial;
            height: auto;
        }
    }

    .new-list-dropdown {
        box-shadow: 0px 3px 6px rgba($black, .26);
        padding: 2.6rem 2.6rem 3rem;
        background: $white;
        position: relative;
        z-index: 10;
        right: 0;
        text-align: left;
        width: 30rem;

        @media (max-width: 1300px) {
            width: 100%;
        }

        h4 {
            float: left;
        }

        .close-button {
            height: auto !important;
            width: auto !important;
            padding: 0 !important;
            float: right;
            margin: 0 !important;
        }


        form {
            width: 100%;

            .primary-button {
                display: flex;
                height: auto;
                width: 100%;
                padding: 5px 20px;
                font-size: 1.2rem;
                margin: 0 auto;
                justify-content: center;

                span {
                    font-size: 1.7rem;
                }
            }

            .form-control {
                max-width: 100%;
                width: 100%;
                height: 3.5rem;
                background: $white;
                border: 1px solid $grey80;
                border-radius: 4px;
                font-size: 1.2rem;
                font-family: $primaryFont;
            }
        }

        .error-text {
            font-size: 1.1rem;
            clear: both;
            display: block;
        }

        option {
            font-family: $primaryFont !important;
        }
    }

    .modal-content {
        border: none;
    }
}

// 202204 AM: Resetting to 1.6rem given our default font-size is 10px. If we ever set font-size back to standard 16px, we can delete the entire modal class below.
.modal {
    color: $black;
    background-color: rgba(0,0,0,.75);

    &-content {
    }

    &-header {
        padding: 1.6rem;

        .close {
            padding: 1.6rem;
            margin: -1.6rem -1.6rem -1.6rem auto;
            font-size: 2.4rem;
            color: $main;
        }
    }

    &-body {
        padding: 1.6rem;
    }
}

.full-width {
    width: 100%;
}

.hr-both-sides {
    position: relative;
    color: $grey90;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 40%;
        height: 1px;
        background-color: $grey90;
    }

    &:before {
        margin-left: -45%;
    }

    &:after {
        margin-left: 5%;
    }
}
